import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogData {
  status: AttemptStatus[];
}

interface AttemptStatus {
  attemptedAt?: string;
  deleted?: boolean;
  failureReason?: string;
}

@Component({
  selector: 'app-status-dialogue',
  templateUrl: './status-dialogue.component.html',
  styleUrls: ['./status-dialogue.component.scss']
})
export class StatusDialogueComponent {
  constructor(
    public dialogRef: MatDialogRef<StatusDialogueComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}
}
