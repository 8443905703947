import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from './loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requestCount: number;
  constructor(private loaderService: LoaderService) {
    this.requestCount = 0;
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const byPassRequest: Array<string> = [
      'admin/get-merchant-groups-list',
      'admin/merchants/get-merchants-filter-list',
      'admin/rules-summary',
      'admin/dashboard-summary',
      'admin/dashboard-summary/cumulative-data',
      'admin/admin-roles/get-roles-filter-list'
      // ,'admin/users/get-users-list'
    ];
    if (byPassRequest.some((reqUrl: string) => request.url.includes(reqUrl))) {
      return next.handle(request);
    }

    this.requestCount++;
    this.loaderService.setLoader(true);
    return next.handle(request).pipe(
      finalize(() => {
        this.requestCount--;
        if (!this.requestCount) {
          this.loaderService.setLoader(false);
        }
      })
    );
  }
}
