import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HeaderComponent } from './layout/header/header.component';
import { SearchFilterComponent } from './layout/search-filter/search-filter.component';
import { TableComponent } from './components/table/table.component';
import { StatusDialogueComponent } from './components/status-dialogue/status-dialogue.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { IsDisabledDirective } from './directives/is-disabled.directive';
import { SideNavbarComponent } from './layout/side-navbar/side-navbar.component';
import { AlertDialogueComponent } from './components/alert-dialogue/alert-dialogue.component';
import { ProfileViewComponent } from './components/profile-view/profile-view.component';
import { NumberPipe } from './pipes/number.pipe';
import { TooltipEllipsesDirective } from './directives/tooltip-ellipses.directive';
import { ModalComponent } from './components/modal/modal.component';
import { DisableLinkDirective } from './directives/is-router-link-disabled.directive';
import { FilterClearButtonComponent } from './components/filter-clear-button/filter-clear-button.component';
import { SelectAllComponent } from './select-all/select-all.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { TimePeriodComponent } from '../views/earn-rules/time-period/time-period.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatRadioModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule,
    MatExpansionModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatTooltipModule
  ],
  declarations: [
    SideNavbarComponent,
    HeaderComponent,
    SearchFilterComponent,
    TableComponent,
    StatusDialogueComponent,
    AlertDialogueComponent,
    DateAgoPipe,
    IsDisabledDirective,
    DisableLinkDirective,
    TimePeriodComponent,
    ProfileViewComponent,
    NumberPipe,
    TooltipEllipsesDirective,
    ModalComponent,
    FilterClearButtonComponent,
    SelectAllComponent,
    CheckboxComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    SideNavbarComponent,
    HeaderComponent,
    SearchFilterComponent,
    TableComponent,
    DateAgoPipe,
    IsDisabledDirective,
    DisableLinkDirective,
    MatMenuModule,
    TimePeriodComponent,
    MatRadioModule,
    TooltipEllipsesDirective,
    SelectAllComponent,
    FilterClearButtonComponent,
    CheckboxComponent
  ]
})
export class SharedModule {}
