import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile-view',
  templateUrl: './profile-view.component.html',
  styleUrls: ['./profile-view.component.scss']
})
export class ProfileViewComponent implements OnInit {
  profileData: {
    adminRoles?: Array<string>;
    email?: string;
    isActive?: boolean;
    userName?: string;
  } = {};
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      adminRoles?: Array<string>;
      email?: string;
      isActive?: boolean;
      userName?: string;
    },
    private readonly router: Router
  ) {
    this.profileData = this.data;
  }

  ngOnInit(): void {}

  backToHome() {
    this.router.navigate(['/dashboard']).catch((e) => console.log(e));
  }
}
