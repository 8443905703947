import { Directive, ElementRef, HostListener } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Directive({
  selector: '[appTooltipEllipses]',
  providers: [MatTooltip]
})
export class TooltipEllipsesDirective {
  constructor(
    private elementRef: ElementRef<HTMLDataElement>,
    private tooltip: MatTooltip
  ) {}

  @HostListener('mouseover') mouseover(): void {
    setTimeout(() => {
      const element = this.elementRef.nativeElement;
      const parentElement = element.parentElement;

      if (
        (parentElement &&
          element.offsetWidth >
            parentElement?.offsetWidth -
              parseFloat(getComputedStyle(parentElement).paddingLeft) -
              parseFloat(getComputedStyle(parentElement).paddingRight)) ||
        element.offsetWidth < element.scrollWidth
      ) {
        this.tooltip.message = element.value
          ? element.value
          : element.innerText;
        this.tooltip.show(500);
      } else if (element.title) element.removeAttribute('title');
    }, 0);
  }

  @HostListener('mouseleave') mouseleave() {
    this.tooltip.hide();
  }
}
