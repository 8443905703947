import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'number'
})
export class NumberPipe implements PipeTransform {
  transform(input: number): string | number | null {
    const suffixes = ['K', 'M', 'B', 'T', 'Q', 'E'];

    if (Number.isNaN(input)) {
      return null;
    }

    if (Math.abs(input) < 1000) {
      return input;
    }

    const exp = Math.floor(Math.log(input) / Math.log(1000));

    return String(input / Math.pow(1000, exp)) + String(suffixes[exp - 1]);
  }
}
