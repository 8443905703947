<div [formGroup]="fg" class="col-s-12">
    <div class="rule-inputs col-s-12" [formGroupName]="'timePeriod'">
        <div class="mat-custom-input">
            <mat-form-field [ngClass]="{'custom-disabled':isViewOnly}" appearance="fill" floatLabel="always"
                class="col-s-12">
                <mat-label>Time Unit</mat-label>
                <mat-select disableOptionCentering formControlName="unit" (selectionChange)="onUnitsChange()"
                    placeholder="Select time unit">
                    <mat-option *ngFor="let option of expirationUnits" [value]="option" [disabled]="isViewOnly">
                        {{option | titlecase}}
                    </mat-option>
                </mat-select>
                <mat-error>
                    This field is mandatory
                </mat-error>
            </mat-form-field>
        </div>

        <div  class="mat-custom-input">
            <mat-form-field [ngClass]="{'custom-disabled':isViewOnly}" appearance="fill" floatLabel="always"
                class="col-s-12">
                <mat-label *ngIf="fg.get('timePeriod')?.get('unit')?.value">{{'Number Of ' + fg.get('timePeriod')?.get('unit')?.value + 's' | titlecase}}</mat-label>
                <mat-label *ngIf="!fg.get('timePeriod')?.get('unit')?.value">Number of Units</mat-label>
                <!-- <mat-label>Time Unit</mat-label> -->
                <input matInput type="number" step="1" max="1000" min="1" formControlName="value"
                    [readonly]="isViewOnly" (keypress)="preventNonNumericalInput($event)">
                <mat-error>
                    Enter a positive integer which is greater than 0 and less than 1001
                </mat-error>
            </mat-form-field>
        </div>
    </div>
</div>