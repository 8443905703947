<form class="search-bar" [formGroup]="searchFilter" (ngSubmit)="onFormSubmit()">

  <div class="search">
    <span><img src="/assets/images/common/search.svg" alt="search"></span>
    <input matInput type="text" placeholder="Search" onfocus="placeholder = '' " onblur="placeholder = 'Search' "
      formControlName="searchInput" />
  </div>

  <mat-form-field class="dropdown day-filter" floatLabel="never" *ngIf="dayFilter.length">
    <mat-label class="label">Day Filter</mat-label>
    <mat-select [disableOptionCentering]="true" formControlName="dayFilter" panelClass="day-filter-dropdown">
      <mat-option *ngFor="let val of dayFilter" [value]="val.value" class="option">
        <mat-radio-button>{{val.viewValue}}</mat-radio-button>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="dropdown filter" floatLabel="never" *ngIf="filters.length">
    <mat-label class="label">FILTER</mat-label>
    <mat-select [disableOptionCentering]="true" formControlName="filters" panelClass="filter-dropdown" multiple>
      <mat-optgroup *ngFor="let group of filters" [label]="group.name">
        <mat-option class="option" *ngFor="let filterValues of group.values" [value]="filterValues.value">
          {{filterValues.viewValue}}</mat-option>
      </mat-optgroup>
    </mat-select>
  </mat-form-field>

  <button class="merchant-btn search-btn">Search</button>

</form>
