import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loader = new Subject<boolean>();

  setLoader(state: boolean) {
    this.loader.next(state);
  }

  getLoader(): Observable<boolean> {
    return this.loader.asObservable();
  }
}
