import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  DayFilter,
  DayFilterValue,
  Filter,
  FilterValue,
  SearchFilterReqBody
} from './search-filter.class';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchFilterComponent implements OnInit {
  @Input() dayFilter: DayFilter[] = [];
  @Input() filters: Filter[] = [];

  @Output() sendSearchFilter = new EventEmitter<SearchFilterReqBody>();

  searchFilterReqBody: SearchFilterReqBody = {};
  searchFilter: FormGroup<{
    searchInput: FormControl<string | null>;
    dayFilter: FormControl<DayFilterValue | null>;
    filters: FormControl<FilterValue[] | null>;
  }>;

  constructor(private fb: FormBuilder) {
    this.searchFilter = this.fb.group({
      searchInput: new FormControl(''),
      dayFilter: new FormControl(),
      filters: new FormControl<FilterValue[]>([])
    });
  }

  ngOnInit(): void {}

  onFormSubmit() {
    const searchFilterValue = this.searchFilter.value;

    // Filters
    if (searchFilterValue.filters?.length) {
      const selectedFilters = searchFilterValue.filters;

      const updatedSearchFilters: SearchFilterReqBody['searchFilters'] = [];
      selectedFilters.forEach((element) => {
        const existingIndex = updatedSearchFilters.findIndex((e) => {
          return e.filterType == element.filterType;
        });
        if (existingIndex >= 0) {
          updatedSearchFilters[existingIndex]?.values.push(element.filterValue);
        } else {
          updatedSearchFilters.push({
            filterType: element.filterType,
            values: [element.filterValue]
          });
        }
      });
      this.searchFilterReqBody.searchFilters = updatedSearchFilters;
    } else {
      this.searchFilterReqBody.searchFilters = [];
    }

    // Day Filter
    if (searchFilterValue.dayFilter) {
      const date = new Date();
      const dayFilterValue = searchFilterValue.dayFilter;
      if (dayFilterValue.count) {
        if (dayFilterValue.type === 'day') {
          date.setDate(date.getDate() - dayFilterValue.count);
        } else if (dayFilterValue?.type === 'month') {
          date.setMonth(date.getMonth() - dayFilterValue.count);
        } else if (dayFilterValue?.type === 'year') {
          date.setFullYear(date.getFullYear() - dayFilterValue.count);
        }
        this.searchFilterReqBody.createdAfter = date.toISOString();
      }
    }

    // Searching
    if (searchFilterValue.searchInput) {
      this.searchFilterReqBody.searchText = searchFilterValue.searchInput;
    } else {
      delete this.searchFilterReqBody.searchText;
    }

    this.sendSearchFilter.emit(this.searchFilterReqBody);
  }
}
