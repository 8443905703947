<div class="container-new" [class.white-background]="isEmbeddedView"  *ngIf="showContent && !isPreview">
    <div class="default-sidebar" *ngIf="!isEmbeddedView">
        <!-- Here goes side-nav -->
        <app-side-navbar></app-side-navbar>
    </div>
    <div class="right">
        <!-- Here goes Header -->
        <app-header></app-header>
        <div class="views">
            <!-- Here goes views -->
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
<ng-container *ngIf="isPreview">
    <router-outlet></router-outlet>
</ng-container>
<app-loader></app-loader>