<div class="preview-profile-pg" [ngClass]="{'zoom-class': fromIframe}">
    <div class="profile-img" [style.background-image]="'url('+images.cover.url+')'"></div>
    <div class="profile-content">
        <div class="preview-header">
            <img class="img-class" [src]="images.logo.url" alt="" height="100" width="100">
            <h1 class="header-txt"> Log in to Portal</h1>
        </div>
        <div class="preview-form">
            <form action="">
                <div class="form-group">
                    <label for="email">Email ID</label>
                    <input readonly type="text" class="preview-form-control" placeholder="Enter Email ID">
                </div>
                <div class="form-group">
                    <label for="email">Password</label>
                    <input readonly type="password" class="preview-form-control" placeholder="Enter Password">
                    <mat-icon class="password-reveal">remove_red_eye</mat-icon>
                </div>
                <div class="form-group remember">
                    <label class="d-flex"> 
                        <input readonly type="checkbox" class="preview-check" disabled>
                        <span class="gray">Remember me</span> 
                    </label>
                    <a>Forgot Password?</a>
                </div>

                <div class="login">
                    <button disabled class="btn login-btn" type="button">Login</button>
                </div>
            </form>
        </div>

    </div>
</div>