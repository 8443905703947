import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SearchUserFilterReqBody } from 'src/app/shared/layout/search-filter/search-filter.class';
import { APP_CONSTANTS } from 'src/app/constants/app-constants';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import {
  AdminUsersServerModel,
  EditUserModel,
  ListRolesModel,
  UserModel
} from './interface';

@Injectable({
  providedIn: 'root'
})
export class AccessManegerService {
  defaultUserModel: UserModel = {
    userName: '',
    phoneNumber: '',
    isActive: false,
    adminRoles: []
  };

  private profile: BehaviorSubject<UserModel> = new BehaviorSubject<UserModel>(
    this.defaultUserModel
  );

  constructor(private readonly httpClient: HttpClient) {}

  submitRule(form: UserModel) {
    return this.httpClient.post(
      `${environment.apiHost}/admin/admin-user`,
      form
    );
  }

  editUser(form: EditUserModel, id: string) {
    return this.httpClient.put(
      `${environment.apiHost}/admin/admin-user/${id}`,
      form
    );
  }

  editUserStatus(
    form: {
      isActive: boolean;
    },
    id: string
  ) {
    return this.httpClient.put(
      `${environment.apiHost}/admin/admin-user/${id}/is-active`,
      form
    );
  }

  getAdminUsers(form: SearchUserFilterReqBody) {
    //Modifying request body so that only "Super Admin" role is returned
    // (Temp change to hide 'partner admin' from UI)
    const reqBody = { ...form, searchFilters: [...(form.searchFilters || [])] };
    const roleFilter = reqBody.searchFilters?.find(
      (filter) => filter.filterType === 'roles'
    );
    if (!roleFilter) {
      reqBody.searchFilters = reqBody.searchFilters || [];
      // reqBody.searchFilters.push({
      //   filterType: 'roles',
      //   values: ['jiox-admin']
      // });
    }
    reqBody.limit = reqBody.limit || APP_CONSTANTS.DEFAULT_PAGE_SIZE;
    return this.httpClient.post<AdminUsersServerModel>(
      `${environment.apiHost}/admin/admin-user/get-admin-users-list`,
      reqBody
    );
  }

  getUserInfo(id: string) {
    return this.httpClient.get<UserModel>(
      `${environment.apiHost}/admin/admin-user/${id}`
    );
  }

  getProfileDetail() {
    return this.httpClient
      .get<UserModel>(`${environment.apiHost}/admin/admin-user/profile`)
      .pipe(
        tap((res) => {
          this.setProfile(res);
        })
      );
  }

  setProfile(value: UserModel) {
    this.profile.next(value);
  }

  getProfile() {
    return this.profile.asObservable();
  }

  getAdminRoles(
    body: {
      searchTerm?: string;
    } = {}
  ): Observable<ListRolesModel> {
    return this.httpClient.post<ListRolesModel>(
      `${environment.apiHost}/admin/admin-roles/get-roles-filter-list`,
      body
    );
  }
}
