import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../components/error-dialog/error-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ErrorHelperService {
  constructor(
    private readonly snackBar: MatSnackBar,
    public readonly dialog: MatDialog
  ) {}

  openSnackBar(message: string) {
    this.snackBar.open(message, 'Close', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      duration: 8000,
      panelClass: ['api-snackbar']
    });
  }

  openErrorDialog(err: Array<string>, title = 'Error List') {
    this.dialog.open(ErrorDialogComponent, {
      data: { errors: err, title: title },
      width: '640px',
      panelClass: 'custom-dialog'
    });
  }

  getMessageStatusWise(status: number) {
    let statusErrorMessage: Map<number, string> = new Map<number, string>();
    statusErrorMessage = new Map([
      [0, 'Something went wrong!'],
      [
        500,
        'The server encountered an unexpected condition which prevented it from fulfilling the request.'
      ],
      [
        502,
        'Backend server is restarting at the moment. Please wait several seconds and repeat your request/action.'
      ],
      [
        400,
        'The request had bad syntax or was inherently impossible to be satisfied.'
      ],
      [
        403,
        'You do not have sufficient rights to access the requested resource.'
      ],
      [404, 'The server has not found anything matching the URI given.']
    ]);
    return statusErrorMessage.get(status);
  }
}
