import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Roles, routes } from '../../constants/app-constants';
import { RouteList } from '../common.model';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private subject = new Subject<boolean>();
  private routeAccessList: { [key: string]: boolean } = {};
  private routeList = routes;
  private isEmbeddedView = new BehaviorSubject<boolean>(false);
  isEmbeddedViewasObservable = this.isEmbeddedView.asObservable();

  constructor(
    private keycloakService: KeycloakService,
    private httpClient: HttpClient
  ) {}

  sendPreviewPage(isP: boolean) {
    this.subject.next(isP);
  }

  setIsEmbeddedView(val: boolean) {
    this.isEmbeddedView.next(val);
  }

  isPreviewPage(): Observable<boolean> {
    return this.subject.asObservable();
  }

  checkRoles() {
    const userRoles = this.keycloakService.getUserRoles();

    const parentRouteList = this.routeList.map((route) => ({
      roles: route.roles,
      shouldHaveAllRoles: route.shouldHaveAllRoles,
      link: route.link
    }));

    const childrenRouteList = this.routeList.reduce(
      (
        accumulator: {
          roles: string[];
          shouldHaveAllRoles: boolean;
          link: string;
        }[],
        currentValue
      ) => {
        const nestedArrayObjects =
          currentValue.subMenu?.map((role) => ({
            roles: role.roles,
            shouldHaveAllRoles: role.shouldHaveAllRoles,
            link: role.link
          })) || [];
        return accumulator.concat(nestedArrayObjects);
      },
      []
    );

    const finalRouteList = [...parentRouteList, ...childrenRouteList];

    finalRouteList.map((route) => {
      if (route.shouldHaveAllRoles) {
        if (route.roles.every((role) => userRoles.includes(role))) {
          this.routeAccessList[route.link] = true;
        }
      } else {
        if (route.roles.some((role) => userRoles.includes(role))) {
          this.routeAccessList[route.link] = true;
        }
      }
    });
  }

  hasAccess(route: string) {
    return this.routeAccessList[route] || false;
  }

  getRouteAccessList() {
    return this.routeAccessList;
  }

  getSideBarNav(): RouteList[] {
    const routeList = this.routeList
      .filter((route) => this.hasAccess(route.link))
      .map((r) => {
        return {
          ...r,
          ...(r.subMenu && {
            subMenu: r.subMenu.filter((f) => this.hasAccess(String(f.link)))
          })
        };
      });
    return routeList;
  }

  doesUserHaveRole(role: string[]) {
    return this.keycloakService
      .getUserRoles()
      .some((aRole) => [Roles.JIOX_ADMIN, ...role].includes(aRole));
  }

  getUserKeycloakToken(jwtToken: string): Observable<{
    token: string;
    resources: { [key: string]: { roles: string[] } };
  }> {
    return this.httpClient.post<{
      token: string;
      resources: { [key: string]: { roles: string[] } };
    }>(environment.apiHost + '/embedded-app/get-auth-token', { jwtToken });
  }
}
