<div *ngIf="!isEmbeddedView" class="header">
  <div class="home">
    <div class="title">
      {{ (headerData$ | async)?.title }}
    </div>
  </div>

  <div class="name">
    <span>Welcome</span>
    <span>{{ name }}</span>
  </div>

  <div>
    <span class="super-user">{{profileData.adminRoles?.includes('jiox-admin') ? 'Super Admin' : profileData.adminRoles && profileData.adminRoles[0]}}</span>
  </div>
  <div class="admin-photo" (click)="viewProfile()">
    <img class="cursor-pointer" src="assets/images/common/user_profile.jpg" alt="photo" />
  </div>
</div>

<ng-container *ngIf="getNumber((headerData$ | async)?.breadcrumbs) > 1 && !isEmbeddedView">
  <div style="display: flex">
    <div routerLink="{{breadcrumb.url}}" class="breadcrumb"
      *ngFor="let breadcrumb of (headerData$ | async)?.breadcrumbs">
      {{ breadcrumb.label }}
    </div>
  </div>
</ng-container>
