import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-filter-clear-button',
  templateUrl: './filter-clear-button.component.html',
  styleUrls: ['./filter-clear-button.component.scss']
})
export class FilterClearButtonComponent implements OnInit {
  @Input() fields!: string[];
  @Input() isDisabledClearBtn!: boolean;

  items = new Map<string, { value: string; viewValue: string }[]>();

  @Output() clearingEmit = new EventEmitter<
    Map<string, { value: string; viewValue: string }[]>
  >();
  constructor() {}

  ngOnInit(): void {}

  clearFilters() {
    this.setMapValues();
    this.clearingEmit.emit(this.items);
  }

  private setMapValues() {
    this.fields.forEach((t: string) => {
      this.items.set(t, []);
    });
  }
}
