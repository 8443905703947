<div class="sidenav" *ngIf="!isEmbeddedView">
  <div class="header">
    <ng-container *ngIf="isShow">
      <div class="side-data cursor-pointer" (click)="goToHome()">
        <img src="/assets/images/sidenav/jio.svg" alt="logo" />
        <span>Loyalty</span>
      </div>
    </ng-container>
    <button [ngClass]="{'m-left':isShow}" class="toggle-btn" mat-mini-fab color="primary" (click)="toggleMe()"
      aria-label="Example fab with a heart icon">
      <mat-icon *ngIf="isShow">keyboard_arrow_left</mat-icon>
      <mat-icon *ngIf="!isShow">keyboard_arrow_right</mat-icon>
    </button>

  </div>
  <div class="navigation">
    <ng-container *ngFor="let c of topCategories">
      <ng-container *ngIf="c.canShow" [ngTemplateOutlet]="c.subMenu && c.subMenu.length ? multipleNav : simplTem"
        [ngTemplateOutletContext]="{nav:c, openState:c.isExpand}">
      </ng-container>
    </ng-container>
  </div>
  <div class="footer">
    <ng-container *ngFor="let c of bottomCategories">
      <ng-container [ngTemplateOutlet]="c.subMenu && c.subMenu.length ? multipleNav : simplTem"
        [ngTemplateOutletContext]="{nav:c, openState:c.isExpand}">
      </ng-container>
      </ng-container>
    <a matTooltip="{{!isShow ? 'Logout' : ''}}" (click)="logout()">
      <img src="/assets/images/sidenav/power-off.svg" alt="logout" />
      <span *ngIf="isShow">Logout</span>
    </a>
  </div>
</div>


<ng-template #simplTem let-c='nav'>
  <a matTooltip="{{!isShow ? c.name : ''}}" routerLinkActive="active" (click)="closeAllExpansionPanels()"
    [routerLink]="c.link" #rla="routerLinkActive" [ngClass]="{'cat-width': isShow }">
    <img src="assets/images/sidenav/{{
    rla.isActive ? c.iconActive : c.icon
  }}.svg" />
    <span *ngIf="isShow">{{ c.name }}</span>
  </a>
</ng-template>

<ng-template #multipleNav let-c='nav' let-accordionOpenState="openState">
  <mat-accordion (click)="handleAccordion(c)">
    <mat-expansion-panel [expanded]="accordionOpenState">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <a [ngClass]="{'cat-width': isShow }" matTooltip="{{!isShow ? c.name : ''}}">
            <img src="assets/images/sidenav/{{
               c.icon
            }}.svg" />
            <span *ngIf="isShow">{{ c.name }}</span>
          </a>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngFor="let subM of c.subMenu">
        <a routerLinkActive="active" [routerLink]="subM.link" #rla="routerLinkActive"
          [ngClass]="{'cat-width': isShow }"><span *ngIf="isShow" class="sub-menu">{{subM.name}}</span></a>
      </ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>