import { RuleOperators } from '../earn-rules/filters/fields';
import { ExpirationUnits } from '../earn-rules/filters/filter';

export type PocDetailsType = {
  pocType: 'primary' | 'technical' | 'marketing' | 'financial';
  isSameAsPrimary: boolean;
  name?: string;
  mobileNumber?: string;
  email?: string;
};
export class MerchantModel {
  _id!: string;
  name!: string;
  pocName?: string;
  authClientId?: string;
  authClientIds?: Array<string> = [];
  pocDetails!: PocDetailsType[];
  mopsExcludedFromEarnAmount?: string[];
  phoneNumber?: string;
  emailAddress?: string;
  isBypassRedeemOTPAllowed?: boolean;
  earnUnlockPeriod?: {
    value: number;
    unit: ExpirationUnits;
  };
  redeemUnlockPeriod?: {
    value: number;
    unit: ExpirationUnits;
  };
  billingType?: 'prepaid' | 'postpaid';
  isCouponRewardAllowed!: boolean;
}

export class MerchantResponseModel {
  authInfo?: {
    clientSecretKey: string;
    id: string;
  };
  merchant?: MerchantModel;
}
export class MerchantProfileModel {
  id!: string;
  merchantGroupId!: string;
  name!: string;
  isActive!: boolean;
  pocDetails!: PocDetailsType[];
  blockchainStatus!: 'pending';
  blockchainAttempts!: BlockchainAttempts;
  billingType?: 'prepaid' | 'postpaid';
  webhookSettings!: WebhookSettings;
  rOneSettings?: ROneSettings;
}
export class BlockchainAttempts {
  attemptedAt!: string;
  failureReason!: string;
}
export class ROneSettings {
  channel!: string;
  storeIds!: string[];
  messageType!: string;
  earnActivityCode!: string;
  redeemActivityCode!: string;
  posId?: string;
  merchantType!: string;
}
export class WebhookSettings {
  webhookUrl!: string;
  primarySecretKey!: string;
  secondarySecretKey!: string;
  eventsSubscribed!: string[];
  status!: 'active' | 'inactive';
  createdBy!: string;
}

export class MerchantSettings {
  isBypassRedeemOTPAllowed!: boolean;
  earnUnlockPeriod?: {
    value: number;
    unit: ExpirationUnits;
  };
  earnUnlockPeriodRules?: {
    earnUnlockPeriod: {
      value: number;
      unit: ExpirationUnits;
    };
    activityCode: string;
    activityFilters: {
      operator: RuleOperators;
      value: string;
      field: string;
      additionalFieldName: string;
    }[];
  }[];
  redeemUnlockPeriod?: {
    value: number;
    unit: ExpirationUnits;
  };
  limits?: {
    pointsPerUser: {
      value: number;
      timePeriod: {
        value: number;
        unit: ExpirationUnits;
      };
    };
  };
  mopsExcludedFromEarnAmount?: string[];
}
export enum PageArrow {
  RIGHT = 'RIGHT',
  LEFT = 'LEFT'
}

export type GetMerchantFilters = {
  limit?: number;
  searchMerchantName?: string;
  createdAfter?: string;
  createdBefore?: string;
  searchFilters?: { filterType: string; values: string[] }[];
  sortBy?: string;
  sortOrder?: string;
  afterKeyset?: {
    _id: string;
    value: string;
  };
  beforeKeyset?: {
    _id: string;
    value: string;
  };
};
export class MerchantList {
  isFirstPage = true;
  isLastPage = true;
  merchants?: Array<MerchantModel>;
  totalCount?: number;
}

export class MerchantTransactions {
  merchantTransactions!: MerchantTransactionObject[];
}

export class MerchantTransactionObject {
  amount?: number;
  blockchainAttempts?: [];
  blockchainStatus?: 'pending' | 'completed' | 'failed';
  merchantId?: string;
  transactionType?: string;
  _id?: string;
}

export class MerchantObject {
  _id?: string;
  name?: string;
  isCouponRewardAllowed?: boolean;
}

export interface MerchantGroupListModel {
  merchantGroups: MerchantObject[];
}

export interface MerchantsListModel {
  merchants: MerchantObject[];
}
export class SearchFilterModel {
  filterType?: string;
  values?: string[];
}

export class UserMerchantGroupListRequestModel {
  searchTerm?: string;
}

export class UserMerchantListRequestModel {
  searchTerm?: string;
  searchFilters?: Array<SearchFilterModel>;
}

export class IdNameModel {
  _id!: string;
  name?: string;
}

export enum MerchantPOCType {
  PRIMARY = 'primary',

  TECHNICAL = 'technical',

  MARKETING = 'marketing',

  FINANCIAL = 'financial'
}

export class Secret {
  clientId!: string;
  clientSecret!: string;
}

export class TokenResponse {
  accessToken!: string;
  validTill!: string;
}
