import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IOptions } from 'src/app/views/earn-rules/filters/filter';

@Component({
  selector: 'app-select-all',
  templateUrl: './select-all.component.html',
  styleUrls: ['./select-all.component.scss']
})
export class SelectAllComponent implements OnInit {
  @Input() options!: IOptions[];
  @Input() activeItems!: Map<string, { value: string; viewValue: string }[]>;
  @Input() node!: string;
  @Output() updateInputValue = new EventEmitter();

  isSelectAll = false;

  constructor() {}

  ngOnInit(): void {}

  selectAll(
    node: string,
    value: { value: string; viewValue: string }[],
    event?: Event
  ) {
    event?.stopPropagation();

    if (
      this.isSelectAll &&
      this.activeItems.get(node)?.length !== value.length
    ) {
      const remainingElements = this.activeItems
        .get(node)
        ?.filter((x) => !this.options.includes(x));

      remainingElements?.length &&
        this.activeItems.set(node, remainingElements);
      this.updateInputValue.emit();
      this.checkSelectAll(this.options);
      return;
    }

    if (
      this.activeItems.get(node)?.length === value.length &&
      this.isSelectAll
    ) {
      this.activeItems.set(node, []);
      this.updateInputValue.emit();
      this.checkSelectAll(this.options);
      return;
    }

    this.activeItems.set(
      node,
      Array(...new Set(this.activeItems.get(node)?.concat(value))) || []
    );
    this.updateInputValue.emit();
    this.checkSelectAll(this.options);
  }

  checkSelectAll(options: IOptions[]) {
    if (
      Array(...new Set(this.activeItems.get(this.node)?.concat(options)))
        .length === this.activeItems.get(this.node)?.length
    ) {
      this.isSelectAll = true;
    } else {
      this.isSelectAll = false;
    }
  }
}
