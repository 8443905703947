import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface ModalData {
  alertMessage: string;
  title: string;
  buttonText: string;
  buttonTextForCancel?: string;
  canAddPadding: boolean;
}

@Component({
  selector: 'app-alert-dialogue',
  templateUrl: './alert-dialogue.component.html',
  styleUrls: ['./alert-dialogue.component.scss']
})
export class AlertDialogueComponent {
  padding = '60px';
  constructor(
    public dialogRef: MatDialogRef<AlertDialogueComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData
  ) {
    this.padding = data.canAddPadding === false ? '0px' : '60px';
  }
  closeDialogue(isDismiss: boolean) {
    this.dialogRef.close({ dismiss: isDismiss });
  }
  closeAlert() {
    this.dialogRef.close();
  }
}
