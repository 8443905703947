<div class="text-right">
    <img mat-dialog-close class="cursor-pointer" src="assets/images/common/close.svg" />
</div>
<div class="col-s-8 mx-auto profile-wrapper">
    <div mat-dialog-title class="title">
        <h1>View Profile</h1>
    </div>
    <div class="d-flex">
        <span class="home" mat-dialog-close (click)="backToHome()">Home</span><span class="home px-1">></span>
        <span class="view-pro">View Profile</span>
    </div>
    <div class="img-box">
        <img src="assets/images/common/user_profile.jpg">
    </div>
    <div mat-dialog-content class="mat-typography">
        <div class="container account-wrapper">
            <ng-container [ngTemplateOutlet]="valueBox"
                [ngTemplateOutletContext]="{label: 'Name', value: profileData.userName}">
            </ng-container>
            <ng-container [ngTemplateOutlet]="valueBox"
                [ngTemplateOutletContext]="{label: 'Email ID', value: profileData.email}">
            </ng-container>
            <ng-container [ngTemplateOutlet]="valueBox"
                [ngTemplateOutletContext]="{label: 'Member Status', value: profileData.isActive ? 'Active' : 'InActive'}">
            </ng-container>
            <ng-container [ngTemplateOutlet]="valueBox"
                [ngTemplateOutletContext]="{ label: 'User Role', value:  profileData.adminRoles?.includes('jiox-admin') ? 'Super Admin' : profileData.adminRoles && profileData.adminRoles.length && profileData.adminRoles[0] || 'Super Admin'}">
            </ng-container>
            <ng-template #valueBox let-label="label" let-value="value">
                <div class="col-s-12 mb-1">
                    <div class="content-box">
                        <label>{{label}}</label>
                        <span class="d-flex">{{value}}</span>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
    <div mat-dialog-actions>
        <button mat-button mat-dialog-close class="actions col-s-10 mx-auto">Cancel</button>
    </div>
</div>
