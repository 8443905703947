<h2 mat-dialog-title class="header">
  <svg  class="info-icon" width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.2494 2.45312C8.56005 2.45312 6.90861 2.95408 5.50394 3.89266C4.09927 4.83123 3.00446 6.16525 2.35796 7.72604C1.71146 9.28682 1.54231 11.0043 1.87189 12.6612C2.20148 14.3181 3.01499 15.8401 4.20956 17.0347C5.40414 18.2292 6.92612 19.0428 8.58304 19.3723C10.24 19.7019 11.9574 19.5328 13.5182 18.8863C15.079 18.2398 16.413 17.145 17.3516 15.7403C18.2901 14.3356 18.7911 12.6842 18.7911 10.9948C18.7911 9.87308 18.5702 8.76236 18.1409 7.72604C17.7116 6.68971 17.0825 5.74809 16.2893 4.95492C15.4961 4.16175 14.5545 3.53258 13.5182 3.10332C12.4819 2.67406 11.3711 2.45313 10.2494 2.45312ZM9.39527 6.29687C9.39527 6.07034 9.48526 5.85308 9.64545 5.69289C9.80564 5.5327 10.0229 5.44271 10.2494 5.44271C10.476 5.44271 10.6932 5.5327 10.8534 5.69289C11.0136 5.85308 11.1036 6.07034 11.1036 6.29687V11.4219C11.1036 11.6484 11.0136 11.8657 10.8534 12.0259C10.6932 12.186 10.476 12.276 10.2494 12.276C10.0229 12.276 9.80564 12.186 9.64545 12.0259C9.48526 11.8657 9.39527 11.6484 9.39527 11.4219V6.29687ZM10.2494 16.5469C9.99603 16.5469 9.74831 16.4717 9.53761 16.3309C9.32691 16.1902 9.16269 15.9901 9.06571 15.7559C8.96874 15.5218 8.94337 15.2642 8.9928 15.0157C9.04224 14.7671 9.16427 14.5388 9.34345 14.3596C9.52264 14.1805 9.75094 14.0584 9.99948 14.009C10.248 13.9596 10.5056 13.9849 10.7397 14.0819C10.9739 14.1789 11.174 14.3431 11.3148 14.5538C11.4555 14.7645 11.5307 15.0122 11.5307 15.2656C11.5307 15.6054 11.3957 15.9313 11.1554 16.1716C10.9151 16.4119 10.5892 16.5469 10.2494 16.5469Z" fill="#F7AB20"/>
    </svg>
   {{data.title}}    
</h2>
<div mat-dialog-content class="mat-typography">
  <div class="container">
    <ul>
      <ng-container *ngFor="let err of data.errors; let i = index">
        <ng-container *ngTemplateOutlet="!seeMore ? seeMoreT : seeLess">
        </ng-container>
        <ng-template #seeMoreT>
          <li *ngIf="i < 5 && !seeMore">
            {{ err }}
          </li>
        </ng-template>
        <ng-template #seeLess>
          <li>
            {{ err }}
          </li>
        </ng-template>
      </ng-container>
    </ul>
    <a [hidden]="data.errors.length < 6" class="error-link cursor-pointer" (click)="seeMore = !seeMore">{{
      !seeMore ? 'Show more...' : 'Less'
    }}</a>
  </div>
</div>
<div mat-dialog-actions class="actions" align="end">
  <button mat-button mat-dialog-close class="cancel">Close</button>
</div>
