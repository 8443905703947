import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import * as fromServices from './services';
import { AuthGuard } from './guards/auth-guard.guard';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { LoaderComponent } from './components/loader/loader.component';

const guards = [AuthGuard];
@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    MatDialogModule,
    MatButtonModule,
    MatProgressSpinnerModule
  ],
  declarations: [ErrorDialogComponent, LoaderComponent],
  providers: [...fromServices.services, ...guards],
  entryComponents: [ErrorDialogComponent],
  exports: [LoaderComponent]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
