import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { Roles } from 'src/app/constants';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AppService } from '../services';
import { UserModel } from '../../views/access-manager/interface';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    protected override readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private appService: AppService,
    private http: HttpClient
  ) {
    super(router, keycloak);
  }

  private redirectToUnauthorizedPage() {
    this.router.navigate(['unauthorize']).catch((error) => console.log(error));
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    const isEmbedded = String(route.queryParams['isEmbedded'] || '');
    const jwtToken = String(route.queryParams['jwtToken'] || '');

    const keyRoles = this.keycloak.getUserRoles();
    if (keyRoles.length > 0) {
      if (
        state.url === '/partner/add' &&
        !keyRoles.includes(Roles.MERCHANT_CREATE) &&
        !keyRoles.includes(Roles.JIOX_ADMIN)
      ) {
        this.redirectToUnauthorizedPage();
      } else if (
        (state.url === '/rules/create-rule?ruleType=baseRule' ||
          state.url === '/rules/create-rule?ruleType=bonusRule' ||
          state.url === '/rules/create-rule?ruleType=pointsMultiplierRule') &&
        !keyRoles.includes(Roles.EARNRULES_CREATE) &&
        !keyRoles.includes(Roles.JIOX_ADMIN)
      ) {
        this.redirectToUnauthorizedPage();
      } else if (
        state.url === '/activity-configuration/derived-fields' &&
        !keyRoles.includes(Roles.DERIVED_FIELDS_READ) &&
        !keyRoles.includes(Roles.JIOX_ADMIN)
      ) {
        this.redirectToUnauthorizedPage();
      } else if (
        state.url === '/activity-configuration/derived-fields/add' &&
        !keyRoles.includes(Roles.DERIVED_FIELDS_CREATE) &&
        !keyRoles.includes(Roles.JIOX_ADMIN)
      ) {
        this.redirectToUnauthorizedPage();
      } else if (
        state.url === '/tiers/create-tier' &&
        !keyRoles.includes(Roles.TIERS_CREATE) &&
        !keyRoles.includes(Roles.JIOX_ADMIN)
      ) {
        this.redirectToUnauthorizedPage();
      } else if (
        state.url === '/webhook/create' &&
        !keyRoles.includes(Roles.WEBHOOK_CREATE) &&
        !keyRoles.includes(Roles.JIOX_ADMIN)
      ) {
        this.redirectToUnauthorizedPage();
      } else if (
        state.url === '/roles/add' &&
        !keyRoles.includes(Roles.ROLE_MANAGER_CREATE) &&
        !keyRoles.includes(Roles.JIOX_ADMIN)
      ) {
        this.redirectToUnauthorizedPage();
      } else if (
        state.url === '/access-management/add' &&
        !keyRoles.includes(Roles.ACCESS_MANAGER_CREATE) &&
        !keyRoles.includes(Roles.JIOX_ADMIN)
      ) {
        this.redirectToUnauthorizedPage();
      } else if (
        state.url === '/subscriptions/add' &&
        !keyRoles.includes(Roles.SUBSCRIPTIONS_CREATE) &&
        !keyRoles.includes(Roles.JIOX_ADMIN)
      ) {
        this.redirectToUnauthorizedPage();
      } else if (
        state.url === '/subscriptions/edit' &&
        !keyRoles.includes(Roles.SUBSCRIPTIONS_UPDATE) &&
        !keyRoles.includes(Roles.JIOX_ADMIN)
      ) {
        this.redirectToUnauthorizedPage();
      }
    }

    if (isEmbedded) {
      if (!jwtToken) {
        this.redirectToUnauthorizedPage();
      }

      await new Promise<void>((resolve) => {
        this.appService.getUserKeycloakToken(jwtToken).subscribe({
          next: ({ token, resources }) => {
            const keycloakInstance = this.keycloakAngular.getKeycloakInstance();

            keycloakInstance.token = token;
            keycloakInstance.resourceAccess = resources;
            keycloakInstance.authenticated = true;

            this.authenticated = true;

            this.appService.checkRoles();

            resolve();
          },
          error: () => {
            this.redirectToUnauthorizedPage();
            return false;
          }
        });
      });
    }

    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url
      });
    }

    // Get the roles required from the route.
    const requiredRoles = environment.roles;

    // Allow the user to proceed if no additional roles are required to access the route.
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true;
    }
    // Allow the user to proceed if any of role is present.
    const path = route.routeConfig?.path || '';

    if (this.appService.hasAccess(path)) {
      if (path === 'tiers' && !environment.enableTiers) {
        this.redirectToUnauthorizedPage();
        return false;
      }
      if (path === 'subscriptions') {
        if (!environment.enableSubscriptions) {
          this.redirectToUnauthorizedPage();
          return false;
        } else {
          return this.http
            .get<UserModel>(`${environment.apiHost}/admin/admin-user/profile`)
            .toPromise()
            .then((response) => {
              if (response.areAllMerchantsAllowed) {
                return true;
              } else {
                this.redirectToUnauthorizedPage();
                return false;
              }
            })
            .catch(() => {
              this.redirectToUnauthorizedPage();
              return false;
            });
        }
      } else {
        return true;
      }
    } else {
      this.redirectToUnauthorizedPage();
      return false;
    }
  }
}
