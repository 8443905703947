import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHelperService } from './error-helper.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorCatchingInterceptor implements HttpInterceptor {
  constructor(private readonly errorHelperService: ErrorHelperService) {}

  private readonly parser = new DOMParser();

  private xmlErrorMessageMapper: Record<string, string> = {
    /* eslint-disable @typescript-eslint/naming-convention */
    EntityTooLarge: 'File Too Large',
    EntityTooSmall: 'File Too Small',
    MalformedSecurityHeader: 'Try again after some time'
    /* eslint-enable @typescript-eslint/naming-convention */
  };

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMsg = '';
        if (
          request.headers.get('errorToShow') &&
          request.headers.get('errorToShow') === 'false'
        ) {
          return throwError(error);
        }
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
          this.errorHelperService.openSnackBar(errorMsg);
        } else if (
          typeof error.error === 'string' &&
          error.error.trim().startsWith('<?xml')
        ) {
          const xmlDoc = this.parser.parseFromString(error.error, 'text/xml');
          const errorElement = xmlDoc.getElementsByTagName('Error')[0];

          if (
            errorElement &&
            errorElement?.getElementsByTagName('Code')[0]?.textContent
          ) {
            const errroCode =
              errorElement?.getElementsByTagName('Code')[0]?.textContent;
            errorMsg =
              (errroCode ? this.xmlErrorMessageMapper[errroCode] : errroCode) ||
              'Unknown error';

            this.errorHelperService.openSnackBar(errorMsg);
          }
        } else {
          const err = (error.error || {}) as {
            error?: string;
            message?: string | string[];
            statusCode?: number;
          };
          if (err.message) {
            if (Array.isArray(err.message)) {
              // show dialog with list of the errors.
              this.errorHelperService.openErrorDialog(err.message);
            } else {
              //show error message.
              this.errorHelperService.openSnackBar(err.message);
            }
          } else {
            //show error according to status code.
            const eMessage = this.errorHelperService.getMessageStatusWise(
              error.status
            );
            this.errorHelperService.openSnackBar(
              `Message: ${eMessage || error.message}`
            );
          }
        }
        console.error(errorMsg);
        return throwError(error);
      })
    );
  }
}
