import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  AllowedMerchantModel,
  DashboardResponseModel,
  OfferDashboardDetailModel
} from './dashboard.class';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor(private readonly httpClient: HttpClient) {}

  isHome = new BehaviorSubject<boolean>(false);

  getDashboardSummaryCumulativeData() {
    return this.httpClient.get<DashboardResponseModel>(
      `${environment.apiHost}/admin/dashboard-summary/cumulative-data`
    );
  }
  getDashboardSummary(fromDate: string, toDate: string) {
    return this.httpClient.get<DashboardResponseModel>(
      `${environment.apiHost}/admin/dashboard-summary?toDate=${toDate}&fromDate=${fromDate}`
    );
  }

  getOfferDashboardDetail(): Observable<OfferDashboardDetailModel> {
    return this.httpClient.get<OfferDashboardDetailModel>(
      `${environment.apiHost}/admin/rules-summary`
    );
  }

  getMerchantOfferDashboardDetail(): Observable<OfferDashboardDetailModel> {
    return this.httpClient.get<OfferDashboardDetailModel>(
      `${environment.apiHost}/admin/rules-summary`
    );
  }

  getMerchantDashboardSummaryCumulativeData(id: string) {
    return this.httpClient.get<DashboardResponseModel>(
      `${environment.apiHost}/merchant/${id}/dashboard-summary/cumulative-data`
    );
  }

  getMerchantDashboardSummary(id: string, fromDate: string, toDate: string) {
    return this.httpClient.get<DashboardResponseModel>(
      `${environment.apiHost}/merchant/${id}/dashboard-summary?toDate=${toDate}&fromDate=${fromDate}`
    );
  }

  getAllowedMerchant(
    body: {
      searchTerm?: string;
    } = {}
  ): Observable<AllowedMerchantModel> {
    return this.httpClient.post<{
      merchants: Array<{ _id: string; name: string }>;
    }>(
      `${environment.apiHost}/admin/merchants/get-merchants-filter-list`,
      body
    );
  }
}
