import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ExpirationUnits } from '../filters/filter';

@Component({
  selector: 'app-time-period',
  templateUrl: './time-period.component.html',
  styleUrls: ['./time-period.component.scss']
})
export class TimePeriodComponent implements OnInit {
  @Input() fg!: FormGroup;
  @Input() enableHour = false;
  @Input() enableYear = true;
  @Input() isViewOnly = false;
  @Input() expirationUnits: ExpirationUnits[] | undefined;
  selectedUnit!: ExpirationUnits;

  constructor() {}

  ngOnInit(): void {
    this.expirationUnits = Object.values(ExpirationUnits);
    if (!this.enableHour) {
      this.expirationUnits = this.expirationUnits.filter(
        (ob) => ob !== ExpirationUnits.HOUR
      );
    }
    if (!this.enableYear) {
      this.expirationUnits = this.expirationUnits.filter(
        (ob) => ob !== ExpirationUnits.YEAR
      );
    }
  }

  onUnitsChange() {
    this.fg.get('timePeriod')?.get('value')?.enable();
  }

  preventNonNumericalInput(e: KeyboardEvent) {
    // e = e || window.event;
    // const charCode = typeof e.which == 'undefined' ? e.keyCode : e.which;
    // const charStr = String.fromCharCode(charCode);
    // if (!charStr.match(/^[0-9]+$/)) e.preventDefault();
    console.log(e.keyCode);
    const key = window.event ? e.keyCode : e.which;
    if (
      e.keyCode == 8 ||
      e.keyCode == 46 ||
      e.keyCode == 37 ||
      e.keyCode == 39 ||
      e.keyCode == 38 ||
      e.keyCode == 40
    ) {
      return true;
    } else if (key < 48 || key > 57) {
      return false;
    } else return true;
  }
}
