/* eslint-disable @typescript-eslint/unbound-method */
import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnInit,
  Optional
} from '@angular/core';
import { RouterLink, RouterLinkWithHref } from '@angular/router';
import { AppService } from 'src/app/core';

@Directive({
  // eslint-disable-next-line  @angular-eslint/directive-selector
  selector: '[routerLink][isLinkDisabled]'
})
export class DisableLinkDirective implements OnInit, AfterViewInit {
  @Input() role!: string[] | string;

  constructor(
    @Optional() routerLink: RouterLink,
    @Optional() routerLinkWithHref: RouterLinkWithHref,
    private el: ElementRef<HTMLElement>,
    private appService: AppService
  ) {
    const link = routerLink || routerLinkWithHref;
    const onClick = link.onClick;

    link.onClick = (...args) => {
      if (
        !this.appService.doesUserHaveRole(
          Array.isArray(this.role) ? this.role : [this.role]
        )
      ) {
        return routerLinkWithHref ? false : true;
      } else {
        return onClick.apply(link, args);
      }
    };
  }

  ngAfterViewInit(): void {
    if (
      !this.appService.doesUserHaveRole(
        Array.isArray(this.role) ? this.role : [this.role]
      )
    ) {
      if (this.el.nativeElement.tagName == 'A') {
        const div = document.createElement('div');
        const old = this.el.nativeElement;
        div.innerHTML = old.innerHTML;
        this.el.nativeElement.parentNode?.replaceChild(div, old);
      }
    }
  }

  ngOnInit() {
    if (
      !this.appService.doesUserHaveRole(
        Array.isArray(this.role) ? this.role : [this.role]
      )
    ) {
      this.el.nativeElement.classList.remove('user-name');
    }
  }
}
