import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AppService } from '../core';
import { ImageObject, Images } from '../views/customize/customize.component';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {
  images: Images = {
    cover: {
      type: 'cover',
      file: undefined,
      url: '',
      defaultURL: '',
      id: 'coverImage'
    },
    logo: {
      type: 'logo',
      file: undefined,
      url: '',
      defaultURL: '',
      id: 'logoImage'
    }
  };
  fromIframe = false;
  constructor(
    public appService: AppService,
    private readonly sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.appService.sendPreviewPage(true);
    if (localStorage.getItem('Images')) {
      const img = JSON.parse(localStorage.getItem('Images') as string) as {
        from: string;
        cover: ImageObject;
        logo: ImageObject;
      };
      this.fromIframe = img.from === 'IFRAME';
      if (img && img.cover && img.logo) {
        this.images = { cover: img.cover, logo: img.logo };
        this.images.logo.url = this.transform(this.images.logo.url);
      }
    }
  }

  transform(url: string | ArrayBuffer | null) {
    return url
      ? (this.sanitizer.bypassSecurityTrustResourceUrl(url as string) as string)
      : '';
  }
}
