import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AccessManegerService } from 'src/app/views/access-manager/access-manager.service';
import { DashboardService } from 'src/app/views/dashboard/dashboard.service';
import { Observable } from 'rxjs';
import {
  Breadcrumb,
  BreadcrumbService,
  HeaderData
} from 'src/app/core/services/breadcrumb.service';
import { AppService } from 'src/app/core';
import { ProfileViewComponent } from '../../components/profile-view/profile-view.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  name = 'User';
  isShow!: boolean;
  profileData: {
    adminRoles?: Array<string>;
    email?: string;
    isActive?: boolean;
    userName?: string;
  } = {};

  breadcrumbs$?: Observable<Breadcrumb[]>;
  headerData$?: Observable<HeaderData>;
  isEmbeddedView = false;

  constructor(
    public readonly dialog: MatDialog,
    private dashboardService: DashboardService,
    private profileService: AccessManegerService,
    private readonly breadcrumbService: BreadcrumbService,
    private appService: AppService
  ) {}

  ngOnInit(): void {
    this.appService.isEmbeddedViewasObservable.subscribe((bool) => {
      this.isEmbeddedView = bool;
    });

    this.breadcrumbs$ = this.breadcrumbService.breadcrumbs$;
    this.headerData$ = this.breadcrumbService.headerData$;

    this.profileService.getProfileDetail().subscribe((response) => {
      this.profileData = response;
      this.name = this.profileData.userName || '';
    });
    this.dashboardService.isHome.subscribe((res) => {
      this.isShow = res;
    });
  }

  viewProfile() {
    this.dialog.open(ProfileViewComponent, {
      data: { ...this.profileData },
      width: '450px'
    });
  }

  getNumber(value: Breadcrumb[] | undefined) {
    return Number(value?.length);
  }
}
