<div class="modal-container">

    <div class="top">
        <div class="cross">
            <img src="assets/images/common/modal-cross.svg" mat-dialog-close>
        </div>


        <div class="middle">
            {{ modalData.description }}
        </div>


        <div align="end" class="actions">
            <button mat-button mat-dialog-close class="cancel">{{ modalData.leftButtonText }}</button>
            <button mat-button class="save" [mat-dialog-close]="true">{{ modalData.rightButtonText }}</button>
        </div>
    </div>