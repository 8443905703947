<div class="container">
    <label class="checkbox-container" *ngIf="isCustomisable" [class.disabled]="disabled"
        (click)="$event.stopPropagation()">
        <input class="checkbox-input" type="checkbox" [ngModel]="checked" (ngModelChange)="onModelChange($event)"
            style="display: hidden" />
        <div class="checkbox" [class.selected]="checked || indeterminate">
            <img *ngIf="checked && !indeterminate" src=" assets/images/common/check.svg">
            <img *ngIf="indeterminate" src=" assets/images/roles/indeterminate.svg">
        </div>
    </label>
    <div class="title">
        <ng-content> </ng-content>
    </div>
</div>

<div class="cross" *ngIf="!isCustomisable">
    <img src="assets/images/roles/close.svg">
</div>