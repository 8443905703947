import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { InputTypes } from 'src/app/constants';
import { UserFilterField, RuleOperators, CriteriaField } from './fields';
import { MerchantObject } from '../../merchant/merchant.class';

export enum RuleStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive'
}

export enum FilterFormNames {
  ACTIVITY_FILTERS = 'activityFilters',
  USER_FILTERS = 'userFilters',
  ITEM_FILTERS = 'itemFilters',
  CRITERIA = 'criteria'
}

export enum RuleDynamicTypes {
  ACTIVITY_LEVEL_RULE = 'activityLevelRule',
  ITEM_LEVEL_RULE = 'itemLevelRule',
  ITEM_COMBINATIONS_RULE = 'itemCombinationsRule',
  ACTIVITY_COMBINATIONS_RULE = 'activityCombinationsRule'
}

export enum AdditionalFieldTypes {
  STRING = 'string',
  BOOLEAN = 'boolean',
  INTEGER = 'integer',
  ENUM = 'enum',
  DATE = 'date',
  ALL_TYPES = 'allTypes'
}

export enum ExpirationUnits {
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year'
}
export interface FilterFormGroupForModel {
  title: string;
  formName: string;
  controls?: IControls[] | undefined;
  placeholder?: string;
  options?: FilterControlForModel[];
}

export interface IControls {
  title: string;
  placeholder?: string;
  formName: string;
  options?: FilterControlForModel[];
  optionsFormName?: string;
}

export interface IOptions {
  value: string;
  viewValue: string;
}

export interface FilterControlForModel {
  value: string;
  viewValue: string;
  operators?: IOptions[];
  inputType?: InputTypes;
  validatorRegex?: RegExp;
  options?: IOptions[];
  patternErrorMessage?: string;
  customValidator?: boolean;
  disabled?: boolean;
  className?: string;
  isAdditionalField?: boolean;
}

export enum AllocationTypes {
  FLAT = 'flat',
  PERCENT = 'percent',
  FORMULA_BASED = 'formula_based',
  DISTRIBUTION_BASED = 'value_distribution'
}

export interface AmountOfPointsForm {
  allocationType: FormControl<AllocationTypes | null>;
  points: FormControl<number | null>;
  percentageOfAmount: FormControl<number | null>;
  roundOffSettings: FormControl<string>;
  factor: FormControl<number | null>;
  operator: FormControl<string>;
  rangeBased: FormControl<boolean>;
  noOfRanges: FormControl<number | null>;
  rangeOfPoints: FormArray<FormGroup<RangeForm>>;
}

export interface RangeForm {
  rangeMin: FormControl<number | null>;
  rangeMax: FormControl<number | null>;
  points: FormControl<number | null>;
}
interface ValidityForm {
  from: FormControl<string | null>;
  till: FormControl<string | null>;
}

export interface ExpirationForm {
  value: FormControl<number | null>;
  unit: FormControl<ExpirationUnits | null>;
  roundUpSetting: FormControl<string | null>;
}

export interface ValidityExpirationForm {
  value: FormControl<number | null>;
  unit: FormControl<string | null>;
}

interface ValueWithTimePeriodForm {
  limitProperty: FormControl<boolean>;
  value: FormControl<number | null>;
  timePeriod: FormGroup<ValidityExpirationForm>;
}

interface TimePeriodForm {
  timePeriod: FormGroup<ValidityExpirationForm>;
}

export interface LimitsForm {
  limitPoints: FormControl<boolean>;
  pointsPerActivity: FormControl<number | null>;
  pointsCumulative: FormGroup<ValueWithTimePeriodForm>;
  triggersPerUser: FormGroup<ValueWithTimePeriodForm>;
}
export interface RuleForm {
  ruleDisplayText: FormControl<string>;
  ruleCode: FormControl<string>;
  ruleDescription: FormControl<string>;
  status: FormControl<RuleStatus>;
  isPointReward: FormControl<boolean>;
  isCouponReward: FormControl<boolean>;
  couponsGroups?: FormControl<string>;
  searchCouponText?: FormControl<string>;
  amountOfPoints: FormGroup<AmountOfPointsForm>;
  validity: FormGroup<ValidityForm>;
  combineActivitiesFrom: FormGroup<TimePeriodForm>;
  combineActivitiesFromPast: FormControl<boolean>;
  expiration: FormGroup<ExpirationForm>;
  limits: FormGroup<LimitsForm>;
  pointsExpiration: FormControl<boolean>;
  searchPartner: FormControl<string>;
  partner: FormControl<string>;
  pointsMultiplierPartners?: FormControl<MerchantObject[]>;
  pointsMultiplier: FormGroup<PointsMultiplierForm>;
  isRewardedPerUnit?: FormControl<boolean>;
}

export interface PointsMultiplierForm {
  startDate: FormControl<string | null>;
  endDate: FormControl<string | null>;
  computeAfterInDays: FormControl<number | null>;
}

export interface RewardsForm {
  amountOfPoints: FormGroup<AmountOfPointsForm>;
  limits: FormGroup<LimitsForm>;
  expiration: FormGroup<ExpirationForm>;
}

export interface BaseFilterForm {
  //TODO: Remove this null
  operator: FormControl<RuleOperators | null>;
  operatorOptions?: FormControl<string>;
  value: FormControl<string | string[]>;
  conditionOptions?: FormControl<string>;
  inputType?: FormControl<string>;
  errorText?: FormControl<string>;
  fileName?: FormControl<string>;
}
export interface UserFilterForm extends BaseFilterForm {
  //TODO: Remove this null
  field: FormControl<UserFilterField | null>;
  additionalFieldName: FormControl<string>;
}

export interface LockPeriodFilterForm extends ActivityFilterForm {
  activityCode: FormControl<string>;
  timePeriod: FormGroup<ValidityExpirationForm>;
  showFilter?: FormControl<boolean>;
}

export interface ActivityFilterForm extends BaseFilterForm {
  field: FormControl<string | null>;
  additionalFieldName?: FormControl<string>;
}

export interface ItemFilterForm extends BaseFilterForm {
  //TODO: Remove this null
  field: FormControl<string | null>;
  additionalFieldName?: FormControl<string>;
}

export interface CriteriaForm {
  //TODO: Remove this null
  criteriaType: FormControl<CriteriaField | null>;
  minimumValue: FormControl<number | null>;
  activityCode?: FormControl<string | null>;
  itemFilters?: FormArray<FormGroup<ItemFilterForm>>;
  activityFilters?: FormArray<FormGroup<ActivityFilterForm>>;
}
