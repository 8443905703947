import {
  Component,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { APP_CONSTANTS } from 'src/app/constants';
import { LoaderService } from 'src/app/core/services/loader.service';
import { PageArrow } from 'src/app/views/merchant/merchant.class';

import {
  Columns,
  NextPrevPagination,
  Pagination,
  TableConfig
} from '../../common.model';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent<T> implements OnChanges {
  @Input() data!: Array<T>;
  @Input() columns!: Columns[];
  @Input() paginationData!: Pagination;
  @Input() set config(tableConfig: Partial<TableConfig>) {
    Object.assign(this._config, tableConfig);
  }
  @Output() dataChange = new EventEmitter();

  @ViewChild(MatSort, { static: true }) matSort!: MatSort;
  @ViewChild('tableReference') tableRef!: ElementRef<HTMLInputElement>;

  _config: TableConfig = {
    sortOrder: '',
    sortOn: '',
    sorting: false
  };
  columnKeys: string[] = [];
  dataSource = new MatTableDataSource();
  pageSizeOptions = [10, 20, 50];
  pageArrows = PageArrow;
  isLoader = false;

  constructor(private loaderService: LoaderService) {
    this.loaderService.getLoader().subscribe((state) => {
      if (state === undefined || state === null) {
        this.isLoader = false;
      } else {
        this.isLoader = state;
      }
    });
  }

  ngOnChanges() {
    this.columnKeys = this.columns.map((column) => column.key);
    this.dataSource.data = this.data || [];
  }

  pageChanged(currentPage: PageEvent) {
    let pageData: { pageNo?: number } | undefined = undefined;
    if (this.paginationData.isTotalRecordsKnown) {
      pageData = {
        pageNo: currentPage.pageIndex + 1
      };
    }

    this.dataChange.emit({
      ...this.sortData,
      limit: currentPage.pageSize,
      pageData
    });
  }

  onPageChange(type: string) {
    const idKey = '_id' as keyof T;
    const sortKey = (this.matSort.active || 'createdAt') as keyof T;
    const pageInfo: NextPrevPagination = {
      limit: APP_CONSTANTS.DEFAULT_PAGE_SIZE
    };
    let key: 'afterKeyset' | 'beforeKeyset' = 'afterKeyset';
    let rowData = this.data[this.data.length - 1];

    if (type === this.pageArrows.LEFT) {
      rowData = this.data[0];
      key = 'beforeKeyset';
    }

    if (rowData) {
      pageInfo[key] = {
        _id: this.getValue(rowData[idKey]),
        value: this.getValue(rowData[sortKey])
      };
    }
    if (this.tableRef && this.tableRef.nativeElement) {
      this.tableRef.nativeElement.scrollTop = 0;
    }

    this.dataChange.emit({
      ...this.sortData,
      ...pageInfo
    });
  }

  private getValue(val: unknown): string {
    if (val == null) {
      return '';
    }
    if (
      typeof val === 'boolean' ||
      typeof val === 'string' ||
      typeof val === 'number'
    ) {
      return val.toString();
    }
    return '';
  }

  private get sortData() {
    if (this._config.sorting && this.matSort?.active) {
      return {
        sortBy: this.matSort.active,
        sortOrder: this.matSort.direction
      };
    }
    return {};
  }

  sortChange(event: Sort) {
    const limit = this.paginationData?.isTotalRecordsKnown
      ? this.paginationData.limit
      : APP_CONSTANTS.DEFAULT_PAGE_SIZE;

    this.dataChange.emit({
      sortBy: event.active,
      sortOrder: event.direction,
      limit
    });
  }
}
