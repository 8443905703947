import { Pipe, PipeTransform } from '@angular/core';

type Intervals = {
  year: number;
  month: number;
  week: number;
  day: number;
  hour: number;
  minute: number;
  second: number;
};

@Pipe({
  name: 'dateAgo',
  pure: true
})
export class DateAgoPipe implements PipeTransform {
  private intervals: Intervals = {
    year: 31536000,
    month: 2592000,
    week: 604800,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1
  };

  transform(value: number | Date | string): unknown {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 59)
        // less than 60 seconds ago will show as 'Just now'
        return 'Just now';
      let counter: number;
      for (const i in this.intervals) {
        counter = Math.floor(seconds / this.intervals[i as keyof Intervals]);
        if (counter > 0)
          if (counter === 1) {
            return `${counter} ${i} ago`; // singular (1 day ago)
          } else {
            return `${counter} ${i}s ago`; // plural (2 days ago)
          }
      }
    }
    return value;
  }
}
