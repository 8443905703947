import { Injectable } from '@angular/core';
import {
  Router,
  NavigationEnd,
  ActivatedRouteSnapshot,
  Data
} from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface Breadcrumb {
  label: string;
  url: string;
}

export interface HeaderData {
  title: string;
  breadcrumbs: Breadcrumb[];
}

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);
  private readonly _headerData$ = new BehaviorSubject<HeaderData>({
    title: '',
    breadcrumbs: []
  });

  // Observable exposing the breadcrumb hierarchy
  readonly breadcrumbs$ = this._breadcrumbs$.asObservable();
  readonly headerData$ = this._headerData$.asObservable();

  constructor(private router: Router) {
    this.router.events
      .pipe(
        // Filter the NavigationEnd events as the breadcrumb is updated only when the route reaches its end
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        // Construct the breadcrumb hierarchy
        const root = this.router.routerState.snapshot.root;
        const breadcrumbs: Breadcrumb[] = [];
        const data: HeaderData = { title: '', breadcrumbs: [] };
        this.addBreadcrumb(root, [], breadcrumbs, data);

        // Emit the new hierarchy
        this._breadcrumbs$.next(breadcrumbs);
        this._headerData$.next(data);
      });
  }

  updateBreadCumLabel(oLabel: string, newLabel: string) {
    this.headerData$.pipe().subscribe((val) => {
      if (val && val.breadcrumbs && val.breadcrumbs.length > 0) {
        val.breadcrumbs.forEach((b) => {
          if (b.label === oLabel) {
            b.label = newLabel;
          }
        });
      }
    });
  }

  private addBreadcrumb(
    route: ActivatedRouteSnapshot | null,
    parentUrl: string[],
    breadcrumbs: Breadcrumb[],
    data: HeaderData
  ) {
    if (route) {
      // Construct the route URL
      const routeUrl = parentUrl.concat(route.url.map((url) => url.path));

      if (route.data['title']) {
        data.title = String(route.data['title']);
      }

      // Add an element for the current route part
      if (route.data['breadcrumb']) {
        const breadcrumb = {
          label: String(this.getLabel(route.data)),
          url: '/' + routeUrl.join('/')
        };
        data.breadcrumbs.push(breadcrumb);
        breadcrumbs.push(breadcrumb);
      }

      // Add another element for the next route part
      this.addBreadcrumb(route.firstChild, routeUrl, breadcrumbs, data);
    }
  }

  private getLabel(data: Data) {
    // The breadcrumb can be defined as a static string or as a function to construct the breadcrumb element out of the route data
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return typeof data['breadcrumb'] === 'function'
      ? // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        data['breadcrumb'](data)
      : data['breadcrumb'];
  }
}
